<template>
  <div id="sso__login" />
</template>
<script>
// eslint-disable-next-line no-unused-vars
/* global GAUTH, GAUTH_Events */
import { mapGetters, mapActions, mapMutations } from 'vuex';
import cookieUtils from '../../utils/cookie';

export default {
  name: 'SSOLogin',
  computed: {
    ...mapGetters('aviationOTC', [
      'validSession',
      'sessionData',
      'logoutUrl',
    ]),
  },
  mounted() {
    this.initializeSSOLogin();
  },
  methods: {
    ...mapActions('aviationOTC', [
      'getLogin',
      'getLogout',
      'initSessionValidity',
    ]),
    ...mapMutations(['incrementApiCallsInProgress', 'decrementApiCallsInProgress']),
    initGauth() {
      GAUTH.init({
        gauthHost: window.AppData.gauthURL,
        callBackWebHostUrl: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
        clientId: 'SSC_AV_OTC',
        locale: window.AppData.locale,
        id: 'sso__login',
        rememberMeShown: true,
        cssUrl: false,
        rememberMeChecked: false,
        customerId: '',
        createAccountShown: false,
        redirectAfterAccountLoginUrl: encodeURIComponent(document.URL),
        generateExtraServiceTicket: true,
        consumeServiceTicket: true,
        socialEnabled: false,
      });
    },
    async ssoLogoutHandler(redirect = false) {
      await this.getLogout();
      this.decrementApiCallsInProgress('initGauthLogout');
      this.decrementApiCallsInProgress('initGauthLoginAttempt');

      if (this.logoutUrl && redirect) window.location.replace(this.logoutUrl);
    },
    async initializeSSOLogin() {
      this.incrementApiCallsInProgress('initGauth');
      const ssoHint = cookieUtils.getCookie('GARMIN-SSO');
      await this.initSessionValidity();

      if (!this.validSession && ssoHint === '1') {
        this.incrementApiCallsInProgress('initGauthLoginAttempt');
        this.initGauth();
        GAUTH.checkAuthentication();

        // SSO Session Expired
        // eslint-disable-next-line camelcase
        GAUTH_Events.addListener('NOT_AUTHENTICATED', () => this.ssoLogoutHandler(false));

        // SSO Session Still active
        // eslint-disable-next-line camelcase
        GAUTH_Events.addListener('AUTHENTICATED', async (e, d) => {
          await this.getLogin(d);
          this.decrementApiCallsInProgress('initGauthLoginAttempt');
        });
      }

      if (this.validSession && !ssoHint) {
        this.incrementApiCallsInProgress('initGauthLogout');
        this.initGauth();
        GAUTH.checkAuthentication();
        // eslint-disable-next-line camelcase
        GAUTH_Events.addListener('NOT_AUTHENTICATED', () => this.ssoLogoutHandler(true));
      }
      this.decrementApiCallsInProgress('initGauth');
    },
  },
};
</script>
