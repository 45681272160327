/* eslint-disable no-restricted-globals */
import { mapGetters, mapActions } from 'vuex';
import ScAviationOTCTab from '@garmin/support-components/src/components/ScAviationOTCTab/index.vue';
import { Analytics } from '@garmin/support-analytics';
import Login from '../../views/components/login.vue';

export default {
  name: 'AviationOTCSubviewTab',
  components: {
    ScAviationOTCTab,
    Login,
  },
  mounted() {
    Analytics.trackAnalyticsEvent({
      event_category: 'Product - Aviation OTC',
      event_action: 'Garmin SSO Status',
      event_label: this.isAuthenticated ? 'Logged In' : 'Not Logged In',
      page_type: 'Product - Aviation OTC',
      link_type: 'Garmin SSO Status',
      product_name: this.product?.displayName,
    });
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'translations',
      'locale',
      'product',
    ]),
    ...mapGetters('aviationOTC', [
      'validSession',
      'sessionData',
      'aviationDocuments',
    ]),
    isAuthenticated() {
      return (
        this.validSession
        && this.sessionData?.customerId
      );
    },
    contentSummaryTranslations() {
      return {
        manuals: this.translations.scTabs.scOtcTab.manuals,
        software: this.translations.scTabs.scOtcTab.software,
        serviceInstallationBulletins: this.translations.scTabs.scOtcTab.serviceBulletins,
        serviceCommuniquesLetters: this.translations.scTabs.scOtcTab.serviceCommuniques,
        engineeringDrawings: this.translations.scTabs.scOtcTab.engineeringDrawings,
      };
    },
  },
  methods: {
    ...mapActions('aviationOTC', ['getOtcDocuments']),
    getIdentifier() {
      const params = new URLSearchParams(window.location.search);
      return (
        params.get('identifier')
        || params.get('productID')
        || params.get('partNumber')
      );
    },
    async fetchAviationDocs() {
      await this.getOtcDocuments({
        customerGuid: this.sessionData?.customerId,
        identifier: this.getIdentifier(),
        locale: (this.locale || '').replace('-', '_'),
      });
    },
    getAccountLoginUrl() {
      return `${window.AppData.garminAccountSignInUrl}?redirect=${encodeURIComponent(document.URL)}`;
    },
    onHelpLinkClick(payload) {
      const {
        // eslint-disable-next-line camelcase
        event_category, event_action, event_label, link, page_type, link_type,
      } = payload;
      Analytics.trackAnalyticsEvent({
        // eslint-disable-next-line camelcase
        event_category, event_action, event_label, product_name: this.product.displayName, page_type, link_type,
      });
      if (link) window.open(link, '_self');
    },
  },
};
